<template>
	<v-container fluid class="mt-4 ml-2 pr-6">
    <!--  -->
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title class="text-subtitle-1">
	  		    Requisiciones
	  		    <v-spacer></v-spacer>

            <!-- Boton para descargar el excel -->
              <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( nomina, 'Requisiciones')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn 
              color="primary" 
              @click="initialize()" 
              small 
              tile
            >
              <v-icon small left>mdi-refresh</v-icon>
              Consultar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row>
	  		  		<v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Selecciona un ciclo</label>
                <v-autocomplete
                  label="Ciclo"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="ciclo"
                  :items="ciclos"
                  item-text="ciclo"
                  item-value="ciclo"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3" >
                <label>Fecha inicio</label>
                <v-text-field
                  filled
                  single-line
                  label="Fecha inicio"
                  type="date"
                  hide-details
                  v-model="fecha_inicio"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" >
                <label>Fecha final</label>
                <v-text-field
                  filled
                  single-line
                  label="Fecha final"
                  type="date"
                  hide-details
                  v-model="fecha_final"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>
              
              <v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Estatus</label>
                <v-select
                  label="Estatus"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="estatus"
                  :items="['Nueva','Pre Autorizada','Procesada']"
                  clearable
                ></v-select>
              </v-col>
	  		  	</v-row>

            <v-row justify="end">

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  filled
                  single-line
                  label="Búscar"
                  append-icon="mdi-magnify"
                  hide-details
                  v-model="buscar"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>


            <!-- REQUISICIONES -->
					  <v-data-table
					    :headers="headers"
					    :items="filterNomina"
					    dense
              :search="buscar"
					    class="elevation-0 mt-4"
					  >

              <!-- Monto con formato -->
              <template v-slot:item.total="{ item }">
                <span>{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.total ) }}</span>
              </template>

              <template v-slot:item.descripcion="{ item }">
                <v-chip color="grey"   small dark v-if="item.descripcion == 'Nueva'">Nueva</v-chip>
                <v-chip color="orange" small dark v-if="item.descripcion == 'Pre Autorizada'">Pre Autorizada</v-chip>
                <v-chip color="blue"   small dark v-if="item.descripcion == 'Pendiente'">Pendiente</v-chip>
                <v-chip color="green"  small dark v-if="item.descripcion == 'Autorizada'">Autorizada</v-chip>
                <v-chip color="black"  small dark v-if="item.descripcion == 'Rechazada'">Rechazada</v-chip>
                <v-chip color="green"  small dark v-if="item.descripcion == 'Aceptada'">Aceptada</v-chip>
                <v-chip color="green" small dark v-if="item.descripcion == 'Procesada'">Procesada</v-chip>
              </template>

					    <template v-slot:item.actions="{ item }">
					      <v-icon
					        small
					        class="mr-2"
					        color="blue"
					        @click="editItem(item)"
					      >
					        mdi-eye
					      </v-icon>
					    </template>
					  </v-data-table>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

    <!-- Dialgo para editar o ver la requisición -->
	  <v-dialog
      v-model="dialog"
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ editedItem.id }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text" v-if="editedIndex >= 0">
        	<span><b>Solicitante: </b> {{ editedItem.nombre_completo }}</span>
        	<br/>
          <b>Estatus: </b> 
          <span>
            <v-chip color="grey"   small dark v-if="editedItem.descripcion == 'Nueva'">Nueva</v-chip>
            <v-chip color="orange" small dark v-if="editedItem.descripcion == 'Pre Autorizada'">Pre Autorizada</v-chip>
            <v-chip color="blue"   small dark v-if="editedItem.descripcion == 'Pendiente'">Pendiente</v-chip>
            <v-chip color="green"  small dark v-if="editedItem.descripcion == 'Autorizada'">Autorizada</v-chip>
            <v-chip color="black"  small dark v-if="editedItem.descripcion == 'Rechazada'">Rechazada</v-chip>
            <v-chip color="green"  small dark v-if="editedItem.descripcion == 'Aceptada'">Aceptada</v-chip>
            <v-chip color="green"  small dark v-if="editedItem.descripcion == 'Procesada'">Procesada</v-chip>
          </span>
          <br/>
          <br/>

          <v-row>
            <v-col cols="12" md="4" lg="3" v-for="(img, i) in editedItem.fotos" :key="i">
              <v-card class="py-4 shadowCard" v-if="img.extension != 'pdf'" @click="verImagen2( img )">
                <v-img :src="url_servidor + img.name" contain aspect-ratio="2"></v-img>
              </v-card>
              <v-card class="py-4 shadowCard" v-if="img.extension == 'pdf'" @click="verImagen2( img )">
                <embed :src="url_servidor + img.name"  type="application/pdf">
              </v-card>              
            </v-col>
          </v-row>

        	<v-row>

            <!-- DESCAR EL EXCEL -->
						<v-col cols="12">
        			<v-btn 
	              class="mr-2" 
	              small dark color="green"
	              @click="exportar()">
	              <v-icon>mdi-microsoft-excel</v-icon>
	            </v-btn>

              <v-btn 
                small 
                color="brown"
                dark      
                v-if="['Pre Autorizada'].includes(editedItem.descripcion) && [28,334].includes(getdatosUsuario.iderp) "    
                :disabled="!requisicionesSelected.length"    
                @click="dialogTipoPago = true"
              >
                Metodo de Pago
              </v-btn> 
        		</v-col>

            <!-- Tabla de requisiciones -->
        		<v-col cols="12">
        			<v-data-table
						    :headers="headersDetalle"
						    :items="editedItem.detalle"
						    class="elevation-0"
						    dense
                show-select
                v-model="requisicionesSelected"
						  >

                <!-- Monto con formato -->
						  	<template v-slot:item.costo_total="{ item }">
						      <span>{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.costo_total ) }}</span>
						    </template>

                <template v-slot:item.cotizacion_sn="{ item }">
                  <span v-for="(foto, i) in item.fotos" :key="i">
                    <v-icon 
                      color="primary" 
                      @click="verImagen( foto.name )"
                      v-if="extensionImagenes.includes( foto.extension )"
                    >
                      mdi-file-image
                    </v-icon>
                  </span>
                </template>

                <template v-slot:item.imagen_pago="{ item }">
                  <span v-for="(foto, i) in item.fotosPago" :key="i">
                    <v-icon 
                      color="primary" 
                      @click="verImagen( foto.name )"
                      v-if="extensionImagenes.includes( foto.extension )"
                    >
                      mdi-file-image
                    </v-icon>
                  </span>
                </template>

                <!-- Eliminado -->
                  <template v-slot:item.actions="{ item }">
                    <span v-if="item.activo_sn == 0">
                      <v-chip small color="red" dark>Eliminada</v-chip>
                    </span>

                      <v-tooltip bottom v-if="item.activo_sn == 1" color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="item.activo_sn == 1"
                            :disabled="editedItem.descripcion == 'Procesada'"
                            small
                            color="red"
                            @click="eliminarRequisicion(item)"
                            v-bind="attrs"
                            v-on="on"
                          >   						       
                          </v-icon>
                        </template>
                    </v-tooltip>
                  </template>

                <!-- Acciones -->
						    <template v-slot:item.estatus="{ item }">    
                   <v-chip color="grey"   small dark v-if="item.estatus == 0">Nueva</v-chip>
                   <v-chip color="green"   small dark v-if="item.estatus == 1">Procesada</v-chip> 
                   <v-chip color="green"   small dark v-if="item.estatus == 3">Procesada</v-chip>
                   <v-chip color="black"   small dark v-if="item.estatus == 4">Rechazada</v-chip>
						    </template>

                <template v-slot:item.actions="{ item }">
                  <span v-if="item.activo_sn == 0">
                    <v-chip small color="red" dark>Eliminada</v-chip>
                  </span>
                  <!-- Eliminar -->
                  <v-tooltip bottom v-if="item.activo_sn == 1" color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.activo_sn == 1"
                        :disabled="editedItem.descripcion == 'Procesada'"
                        small
                        color="red"
                        @click="eliminarRequisicion(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-close
                      </v-icon>
                    </template>
                    <span>¿Eliminar?</span>
                  </v-tooltip>
                </template>        
						  </v-data-table>
            </v-col>
        	</v-row>
        </v-card-text>

        <v-card-actions>
          <!-- <v-btn 
            small 
            color="secondary"
            dark
            v-if="['Nueva'].includes(editedItem.descripcion)"
            @click="preAutorizar( editedItem.descripcion )"
          >
            Rechazar
          </v-btn> -->
          <v-btn 
            small 
            color="primary"
            dark
            v-if="['Nueva'].includes(editedItem.descripcion)"
            @click="preAutorizar( editedItem.descripcion )"
          >
            Preautorizar
          </v-btn>
          
          <v-btn 
            :disabled="!requisicionesSelected.length"
            small 
            color="red"
            dark
            v-if="['Nueva','Rechazada'].includes(editedItem.descripcion)"
            @click="rechazarRequi( editedItem.descripcion )"
          >
            Rechazar
          </v-btn>

          <!-- <v-btn 
            small 
            color="green"
            dark
            v-if="['Pendiente'].includes(editedItem.descripcion)"
            @click="updateEstatus( 6 )"
          >
            Aceptar
          </v-btn> -->

          <v-btn 
            small 
            color="black"
            dark
            v-if="['Pendiente'].includes(editedItem.descripcion)"
            @click="updateEstatus( 5 )"
          >
            Rechazar
          </v-btn>

          <v-spacer></v-spacer>
          <span class="text-h6">
            TOTAL: <!-- Monto con formato -->
            <span>{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( editedItem.total ) }}</span>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Visualizar imagenes -->
    <v-dialog
      v-model="dialogImagen"
      max-width="600px"
    >
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-img
              :src="url_servidor + imagen"
              contain
              max-width="600"
              height="750"
            >
            </v-img>
          </v-col>
        </v-card-text>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          color="grey"
          dark
          fab
          class="elevation-6 mt-8"
          @click="dialogImagen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogTipoPago"
      max-width="350px"
    >
      <v-card>
        <v-card-title>
          Metodo de Pago
        	<v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="dialogTipoPago = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text">
        	<v-row>
        		<v-col cols="12">
			    		<v-autocomplete
			    		  label="Tipo de pago"
			          filled
			          dense
			          clearable
			          v-model="tipopago"
			          :items="[
                          { id_tipopago: 1 , tipopago: 'Tarjeta' },
                          { id_tipopago: 2 , tipopago: 'Transferencia/Deposito' },
                          { id_tipopago: 3 , tipopago: 'Efectivo'  }
                        ]"
			          persistent-hint
			          item-text="tipopago"
			          item-value="id_tipopago"
                @input="handleTipoPagoChange()"
			        >
			        </v-autocomplete>

              <v-autocomplete
			    		  label="Cuenta de procedencia"
			          filled
			          dense
			          clearable
			          v-model="cuentametodopago"
			          :items="cuentas"
			          persistent-hint
			          item-text="cuenta_completa"
			          item-value="idcuentas_escuela"
			        >
			        </v-autocomplete>
             

	    			</v-col>
        	</v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn dark block @click="tipospago()" color="primary">Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVer" max-width="600px" v-if="img" persistent :fullscreen="fullscreen">
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card height="100%" class="elevation-0" align="center" outlined v-if="img.extension != 'pdf'">
              <v-img :src=" url_servidor + img.name" contain aspect-ratio="2" v-if="fullscreen"></v-img>

              <v-img :src=" url_servidor + img.name" contain aspect-ratio="2" max-width="800" v-else ></v-img>
            </v-card>

            <v-card v-else>
              <embed :src="url_servidor + img.name"  type="application/pdf">
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text class="mr-2" @click="fullscreen = true" v-if="!fullscreen">Zoom</v-btn>
          <v-btn color="error" text class="mr-2" @click="fullscreen = false" v-else>Sin, Zoom</v-btn>
          <!-- Guardar la información  -->
          <v-btn
            color="primary" 
            dark 
            class="elevation-6" 
            @click="dialogVer = false"
          >Okey</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'


  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      mostrarBoton: false,

      dialog: false,
      dialogDelete: false,
      dialogTipoPago: false,

      headers: [
        { text: 'ID'          , value: 'id', sortable: false },
        { text: 'Usuario'     , value: 'nombre_completo' },
        { text: 'Ciclo'       , value: 'ciclo' },
        { text: 'Motivo'      , value: 'comentarios' },
        { text: 'Proyecto'    , value: 'proyecto' },
        { text: 'Estatus'     , value: 'descripcion' },
        { text: 'Fecha'       , value: 'fecha_alta' },
        { text: 'Total'       , value: 'total' },
        { text: 'Actions'     , value: 'actions', sortable: false },
      ],

      headersDetalle: [
        { text: 'ID'           , value: 'id', sortable: false },
        { text: 'Elemento'     , value: 'elemento_mantenimiento' },
        { text: 'Concepto'     , value: 'concepto'       },
        { text: 'Plantel'      , value: 'plantel'        },
        { text: 'Proyecto'     , value: 'proyecto'       },
        { text: 'Cantidad'     , value: 'cantidad'       },
        { text: 'Costo Unit.'  , value: 'costo_unitario' },
        { text: 'Total'        , value: 'costo_total'    },
        { text: 'Estatus'      , value: 'estatus',       },
        { text: 'Eliminar'  , value: 'actions',       },
        // { text: 'Pago'         , value: 'imagen_pago'    },
        // { text: 'Actions'      , value: 'actions', sortable: false },
      ],

      requisicionesSelected:[],

      nomina: [],
      opVisualizacion: 1,

      editedIndex: -1,

      editedItem: {
        id: '',
        nombre_completo: '',
        detalles: [],
      },

      defaultItem: {
        idsolicitud_nomina: '',
        id_usuario: 0,
        idareas_ticket: 0,
      },

      file: '',
      dragging: false,
      vistaPrevia: null,
      autorizar:null,
      nota_rechazo:'',
      url:'',
      timerId:0,

      fecha_inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			buscar:'',

			dialogNuevaNomina:{
				estatus: false
			},

      dialogSubirComprobante: false,
      archivos:null,
      dialogImagen:false,
      url_servidor: '',
      imagen:'',
      extensionImagenes: ['BMP', 'GIF', 'jpg', 'jpeg', 'png', 'webp'],
      total:0,
      tipo_pago:0,
      tipopago: '',
      cuentametodopago: '',
      efectivometodopago: '',
      showCuentaMetodoPago: false,
      showEfectivo: false,
      url:'',
      dialogVer: false,
      fullscreen:false,
      img: null,
      ciclos   : [],
      ciclo    : null,
      estatus  : null,
      cuentas  :[]
    }),

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },

      filterNomina(){
        let data = this.nomina
        
        if( this.ciclo ){
          data = data.filter( el => el.ciclo == this.ciclo )
        }

        if( this.estatus ){
          data = data.filter( el => el.descripcion == this.estatus )
        }


        return data
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      "dialogNuevaNomina.estatus"(val){
        if (!val){
          this.initialize()
        }
      }
    },

    created () {
      this.getCuentas()

      const partes = this.fecha_inicio.split('-') 
      this.fecha_inicio = partes[0] + '-' + partes[1] + '-01'

      this.url = axios.defaults.baseURL + 'justificantes/'
      this.url_servidor = axios.defaults.baseURL + 'fotos-egresos/'

      this.initialize()
      this.getCiclos()

    },

    methods: {

      getCuentas( ){
        this.cargar = true
        this.cuentas = []
        return this.$http.get('admin.cuentas.list').then(response=>{
          this.cuentas = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize () {
        this.nomina = []
        this.cargar = true

        const payload = {
        	fecha_inicio: this.fecha_inicio,
        	fecha_final : this.fecha_final,
          ciclo       : this.ciclo
        }

        return this.$http.post('consultar.requisiciones', payload).then(response=>{
        	this.nomina = response.data
        	this.cargar = false

          if (this.getdatosUsuario.iderp == 28){
            this.nomina = this.nomina.filter((prueba) => { return prueba.descripcion == 'Pre Autorizada' || prueba.descripcion === 'Procesada' ;});
          }

          // Validar si ya hay información, para poder subir el comprobante o mostrarlos
          if( this.editedIndex > -1 ){
            const nomina = this.nomina.find( el => el.id == this.editedItem.id )

            this.editedItem = nomina
          }

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclos () {
        this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
          this.ciclos = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.nomina.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
      	this.autorizar = null
        this.requisicionesSelected = []
      	this.nota_rechazo = ''
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      exportar( ){
      	this.exportExcel( this.editedItem.detalles, 'excel_nomina')
      },

      grabarDatos( ){
        
        var formData = new FormData();

        const ids = this.requisicionesSelected.map(( registro ) => { return registro.id })
        
        formData.append("file"              , this.file );
        formData.append("requi"             , ids );
        formData.append("id_usuario_subio"  , this.getdatosUsuario.iderp );
        formData.append("tipo_pago"         , this.tipo_pago );

        this.cargar = true

        return this.$http.post('subir.archivos.requi', formData).then(response=>{
          this.validarSuccess( response.data.message )
          this.file                   = null
          this.requisicionesSelected  = []
          this.vistaPrevia            = null
          this.cargar                 = false
          this.dialogSubirComprobante = false
          this.initialize( )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verImagen( imagen ){
        this.imagen       = imagen
        this.img          = imagen
        this.dialogImagen = true
      },

      verImagen2( imagen ){
        this.img = imagen
        this.dialogVer = true
      },

      eliminarRequisicion( value ){
        this.cargar = true
        return this.$http.put('eleminar.partida/' + value.id ).then(response=>{
          this.validarSuccess( response.data.message )
          this.cargar                 = false
          this.initialize( )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      preAutorizar( estatus ){
      this.cargar = true

      const payload = {
        id_usuario_autoriza_rechaza  : this.getdatosUsuario.iderp,
        id_requisicion_compra_estatus: 5,
        id_requisicion: this.editedItem.id
      }

    
      return this.$http.post('requisicion.preautorizar', payload ).then(response=>{
        this.validarSuccess( response.data.message )
        setTimeout(() => {
          this.cerrarDialog()
        }, 1500)
        this.cargar = false
        this.initialize()
      }).catch(error =>{
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false })
      },

      rechazarRequi( estatus ){
      this.cargar = true

      const payload = {
        id_usuario_autoriza_rechaza  : this.getdatosUsuario.iderp,
        id_requisicion_compra_estatus: 4,
        id_requisicion: this.editedItem.id
      }

       const ids = this.requisicionesSelected.map((index)=>{ return index.id});
       const payload2 = {
        id  : ids,
      }

      return this.$http.post('requisicion.preautorizar', payload )
       .then(response=>{
         this.$http.post('update.rechazar.partida.requi', payload2 )
           .then(response=>{
            this.validarSuccess( response.data.message )
            this.requisicionesSelected = []     
            setTimeout(() => {
              this.cerrarDialog()
            }, 1500)
            this.cargar = false
            this.initialize()
      }).catch(error =>{
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false })
      }).catch(error =>{
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false })
      },

       estatusProcesar( estatus ){
        this.cargar = true

        const payload = {
          id_usuario_autoriza_rechaza  : this.getdatosUsuario.iderp,
          id_requisicion_compra_estatus: 6,
          id_requisicion: this.editedItem.id
        }

        return this.$http.post('requisicion.preautorizar', payload ).then(response=>{
          this.validarSuccess( response.data.message )
          this.cargar                 = false
          this.initialize( )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      updateEstatus( estatus ){
        this.cargar = true

        const payload = {
          id_requisicion_compra_estatus: estatus,
          id_requisicion: this.editedItem.id
        }

        return this.$http.post('update.estatus.requisicion', payload ).then(response=>{
          this.validarSuccess( response.data.message )
          this.cargar                 = false
          this.initialize( )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      tipospago(){
        this.cargar = true

        const seleccionados = this.requisicionesSelected;
        const ids = seleccionados.map((index) => {
          return index.id;
        });

        if ((this.tipopago == 1 || this.tipopago == 2) && (this.cuentametodopago == '' || this.cuentametodopago == null) ) {
          return this.validarErrorDirecto("Favor de seleccionar una cuenta de procedencia");
        }

        if (this.tipopago == 3){
          this.cuentametodopago = this.efectivometodopago
        }

        const payload = {
         tipo_pago: this.tipopago,
         numero_cuenta: this.cuentametodopago,
         id: ids,
         id_usuario_autoriza_rechaza  : this.getdatosUsuario.iderp,
         id_requisicion_compra_estatus: 7,
         id_requisicion: this.editedItem.id
        }

        return this.$http.post('egresos.mandar.egresos', payload ).then(response=>{
            this.validarSuccess( response.data.message )
            this.cargar = false
            this.dialogTipoPago = false
            this.initialize( )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })

      },

    handleTipoPagoChange() {
    if (this.tipopago === 1 || this.tipopago === 2) {
      this.showCuentaMetodoPago = true;
    } else {
      this.showCuentaMetodoPago = false;
      this.cuentametodopago = null; // Limpiar el valor del campo si no está visible
    }

    if (this.tipopago === 3) {
      this.showEfectivo = true;
    } else {
      this.showEfectivo = false;
      this.efectivometodopago = null; // Limpiar el valor del campo si no está visible
    }
    },

    },
  }
</script>
<style scoped>
  .dropZone {
    width: 100%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>